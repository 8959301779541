/* global $, google */
/* jshint strict: false */

var $html = $('html'),
    $overlay = $('.site-overlay'),
    //$left = $('.button-toggle-menu'),
    $topbar = $('.topbar'),
    $site = $('.site-container, .site-body');

$html.addClass(isTouchDevice() ? 'touch-device' : 'no-touch-device');
$html.addClass('animated');

// Mail to friend

$('[data-mail-to]').on('click', function (e) {
    e.preventDefault();

    var url = document.location.href,
        title = $('[property="og:title"]').attr('content'),
        description = $('[property="og:description"]').attr('content'),
        subject = encodeURI(title),
        body = encodeURI(title + '\n\n' + description + '\n\n' + url);

    document.location = 'mailto:?subject=' + subject + '&body=' + body;
});

// Smart go back

$('[data-go-back]').on('click', function (e) {
    e.preventDefault();

    var i;

    if (document.referrer !== window.location.href) {
        i = -1;
    } else {
        i = -2;
    }

    if (document.referrer.split('/')[2] === window.location.host) {
        window.history.go(i);
    } else {
        window.location = $(this).data('go-back');
    }
});

// Upload button

$('#resume-upload').on('change', function () {
    $(this).parent().addClass('resume-processing');
    $(this).closest('form').submit();
});

// Share it, hijack email button

$('.shares').on('click', '.at-svc-email', function (e) {
    e.preventDefault();

    return false;
});

// Ajax replace

$('body').on('click', '[data-ajax-replace]', function (e) {
    e.preventDefault();

    var url = $(this).attr('href'),
        selector = $(this).data('ajax-replace'),
        $elmt;

    if (selector) {
        $elmt = $(this).parents(selector);
    } else {
        $elmt = $(this);
    }

    $.ajaxSetup({ cache: false });

    $.get(url).then(function (response) {
        $elmt.replaceWith(response);

        $.ajaxSetup({ cache: true });
    });
});

// Url

function cleanUrl() {
    var url = window.location.href;

    url = decodeURIComponent(url);
    url = url.replace(' ', '+');

    window.history.replaceState({}, window.title, url);
}
cleanUrl();

// Topbar

$site.on('scroll', throttle(changeTopBar, 250));

changeTopBar();

function changeTopBar() {
    var scroll = $(this).scrollTop();

    $topbar.removeClass('topbar-primary topbar-secondary');

    if (scroll > 50) {
        $topbar.addClass('topbar-primary');
    }

    if (scroll > $('.block-splash').outerHeight() - 60) {
        $topbar.addClass('topbar-secondary');
    }
}

// Sidebar

/*var toggleSidebar = function (e) {
    e.preventDefault();
    $html.toggleClass('site-menu-opened');
    $('.navigation-menu .has-children.active').find('.sous-menu').show();
};

$left.on('click', toggleSidebar);
$overlay.on('click', toggleSidebar);
*/

// Auth Social go to an url with form params in query string

$('[data-with-form]').on('click', function (e) {
    e.preventDefault();

    var values = $($(this).data('with-form') || 'form').serialize(),
        url = $(this).attr('href') + '?' + values;

    document.location = url;
});

// Laravel

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// TransitionEnd

function transitionEnd() {
    var element = document.createElement('div'),
        transition,
        transitions = {
            'transition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'MozTransition': 'mozTransitionEnd',
            'WebkitTransition': 'webkitTransitionEnd'
        };

    for (transition in transitions) {
        if (element.style[transition] !== undefined) {
            return transitions[transition];
        }
    }
}

// Confirm

$('[data-confirm]').on('click', function (e) {
    // Disable confirmation
    // Asked by client

    // var ok = confirm('Etes-vous sûr ?');

    // if (!ok) {
    //     return false;
    // }
});

$('[dashboard-delete-account]').on('click', function (e) {
    var ok = confirm('Vous êtes sur le point de supprimer votre compte. Confirmez-vous ? \n Cette action est définitive.\nToutes vos données et candidatures seront supprimées.');

    if (!ok) {
        return false;
    }
});

// Modal

$('[data-modal]').on('click', function (e) {
    e.preventDefault();

    var url = $(this).attr('href'),
        settings = $(this).data('modal') || {};

    $.openModal(url, settings);
});

$('[data-modal-close]').on('click', function (e) {
    e.preventDefault();

    var url = $(this).attr('href');

    parent.document.location = url;
});

$.closeModal = function () {
    $('html').removeClass('modal-shown');

    $('.modal').on(transitionEnd(), function () {
        $(this).remove();
    });

    $('.modal-overlay').on(transitionEnd(), function () {
        $(this).remove();
    });
};

$(document).on('keyup', function (e) {
    if (e.keyCode === 27) {
        try {
            $.closeModal();
            parent.$.closeModal();
        } catch (e) { }
    }
});

$.openModal = function (url, settings) {
    var $overlay, $modal, $close, $iframe, options;

    options = $.extend({}, {
        width: null,
        height: 'auto'
    }, settings);

    $modal = $([
        '<div class="modal">',
        '<div class="modal-close">',
        '<i class="material-icons" aria-hidden="true">highlight_off</i>',
        '</div>',
        '<iframe frameborder="0" />',
        '</div>',
    ].join(''));
    $modal.css('width', options.width);
    $modal.css('height', options.height);
    // $modal.css('maxHeight', '80vh');

    $overlay = $('<div class="modal-overlay"></div>');
    $overlay.on('click', function () {
        $.closeModal();
    });

    $close = $modal.find('.modal-close');
    $close.on('click', function () {
        $.closeModal();
    });

    $iframe = $modal.find('iframe');
    $iframe.attr('src', url);

    $iframe.get(0).onload = function () {
        if (options.height === 'auto') {
            $modal.css('height', (this.contentWindow.document.body.scrollHeight + 0) + 'px');
        }

        $('html').addClass('modal-shown');
    };

    // $('.site-container').append($overlay, $modal);
    $('body').append($overlay, $modal);

    return this;
};

// Semantic close message

$('.message .close').on('click', function () {
    $(this).closest('.message').transition('fade');
});

// Image Grid

function applyImageGrid(pictures) {
    imageGrid(pictures);

    var timeout = null;

    $(window).on('resize', function () {
        clearTimeout(timeout);

        $('.job-pictures-small, .job-pictures-large').css('opacity', 0);

        timeout = setTimeout(function () {
            imageGrid(pictures);
        }, 100);
    });
}

function imageGrid(pictures) {
    var options = {
        images: pictures,
        rowHeight: 200,
        maxRowHeight: 400,
        margin: 1,
        thumbnailPath: function (picture) {
            return picture.path;
        },
        getSize: function (picture) {
            return {
                width: picture.width,
                height: picture.height
            };
        }
    };

    $('.job-pictures-large').empty().justifiedImages(options);
    $('.job-pictures-small').empty().justifiedImages($.extend({}, options, {
        rowHeight: 50
    }));

    $('.job-pictures-small, .job-pictures-large').fadeTo(100, 1);
}

// Time ago

if ($.timeago) {
    $.timeago.settings.strings = {
        prefixAgo: "il y a",
        prefixFromNow: "d'ici",
        seconds: "une minute",
        minute: "une minute",
        minutes: "%d minutes",
        hour: "une heure",
        hours: "%d heures",
        day: "un jour",
        days: "%d jours",
        month: "un mois",
        months: "%d mois",
        year: "un an",
        years: "%d ans"
    };

    $('.timeago').timeago();
}

// Watch markers

var markers = [],
    markerStarted = false;

if ($('[data-watch]').length) {
    getMarkers();
    watchMarkers();

    $(window).on('resize', throttle(function () {
        getMarkers();
        watchMarkers();
    }, 250));

    $site.on('scroll', throttle(watchMarkers, 100));
}

function getMarkers() {
    markers = [];

    $('[data-watch]').each(function () {
        markers.push({
            elmt: this,
            position: $(this).offset().top,
            class: $(this).data('watch') || 'animated',
            delay: $(this).data('delay') || 0,
            offset: $(this).data('offset') || 0
        });
    });
}

function watchMarkers() {
    var h = $site.height(),
        y = $site.scrollTop();

    if (markerStarted) {
        $('.timeline').removeClass('timeline-starting');
    } else {
        markerStarted = true;
    }

    markers.forEach(function (item) {
        if (y + h >= item.position + item.offset) {
            setTimeout(function () {
                if (!$(item.elmt).hasClass(item.class)) {
                    $(item.elmt).addClass(item.class);
                }
            }, item.delay);
        }
    });
}

// Google map

function initMap() {
    var mapPinRatio = 8;
    var w = 500 / mapPinRatio;
    var h = 400 / mapPinRatio;
    var mapPin = new google.maps.MarkerImage(
        '/img/pin.png',
        new google.maps.Size(71, 71),
        new google.maps.Point(0, 0),
        new google.maps.Point(w / 2, h),
        new google.maps.Size(w, h)
    );

    var mapCoords = {
        sophia: {
            lat: 43.591360143649545,
            lng: 7.056999182367463
        },
        //paris: {
        //lat: 48.868461,
        //lng: 2.369585
        //},
        marseille: {
            // lat: 43.509460,
            // lng: 4.985030
            lat: 43.088876,
            lng: 6.0753761
        }
    };

    var mapSophia = new google.maps.Map(document.getElementById('map-sophia'), {
        center: mapCoords.sophia,
        zoom: 17,
    });

    new google.maps.Marker({
        position: mapCoords.sophia,
        map: mapSophia,
        icon: mapPin
    });

    /*var mapParis = new google.maps.Map(document.getElementById('map-paris'), {
        center: mapCoords.paris,
        zoom: 17,
    });

    new google.maps.Marker({
        position: mapCoords.paris,
        map: mapParis,
        icon: mapPin
    });*/

    var mapMarseille = new google.maps.Map(document.getElementById('map-marseille'), {
        center: mapCoords.marseille,
        zoom: 17,
    });

    new google.maps.Marker({
        position: mapCoords.marseille,
        map: mapMarseille,
        icon: mapPin
    });
}

// Divers

function isTouchDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

function debounce(fn, wait, immediate) {
    var timeout;

    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;

            if (!immediate) {
                fn.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            fn.apply(context, args);
        }
    };
}

function throttle(fn, threshhold, scope) {
    threshhold = threshhold || 250;
    var last,
        deferTimer;

    return function () {
        var context = scope || this;
        var now = +new Date(),
            args = arguments;

        if (last && now < last + threshhold) {
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

// ***************************************
// Initializing filter counts on page load
// ***************************************

function initProfessionsCount() {
    var selectedProfessionsCount = $('#professions-selector .scrolling-list').find('input:checked').length;

    $('#selected-prof-count').text(selectedProfessionsCount);

    if (selectedProfessionsCount < 2) {
        $('#selected-prof-label').text('métier sélectionné');
    } else {
        $('#selected-prof-label').text('métiers sélectionnés');
    }
}

function initContractsTypesCount() {
    var selectedContractsTypesCount = $('#types-selector .scrolling-list').find('input:checked').length;

    $('#selected-types-count').text(selectedContractsTypesCount);

    if (selectedContractsTypesCount < 2) {
        $('#selected-types-label').text('type de contrats');
    } else {
        $('#selected-types-label').text('types de contrats');
    }
}

function initWorkModesCount() {
    var selectedWorkModesCount = $('#work-modes-selector .scrolling-list').find('input:checked').length;

    $('#selected-work-modes-count').text(selectedWorkModesCount);

    if (selectedWorkModesCount < 2) {
        $('#selected-work-modes-label').text('mode de travail');
    } else {
        $('#selected-work-modes-label').text('modes de travail');
    }
}

initProfessionsCount();
initContractsTypesCount();
initWorkModesCount();

// *************************************************
// Managing filters checkboxes state after page load
// *************************************************

function manageProfessionsSelector() {
    $('#professions-selector input:checkbox').on('change', function () {
        initProfessionsCount();
    });
}

function manageContractsTypesSelector() {
    $('#types-selector input:checkbox').on('change', function () {
        initContractsTypesCount();
    });
}

function manageWorkModesSelector() {
    $('#work-modes-selector input:checkbox').on('change', function () {
        initWorkModesCount();
    });
}
manageProfessionsSelector();
manageContractsTypesSelector();
manageWorkModesSelector();


// Changing arrow up icon to arrow down 
$(document).on('click', '.arrow-up', function () {
    $(this)
        .removeClass('arrow-up')
        .addClass('arrow-down');
});

// Changing arrow down icon to arrow up  
$(document).on('click', '.arrow-down', function () {
    $(this)
        .removeClass('arrow-down')
        .addClass('arrow-up');
});

// Toggling the professions selection pop-up
$('#toggle-professions').on('click', function () {
    if ($('#types-list').parent().is(':visible')) {
        $('#toggle-types').click();
    }
    if ($('#work-modes-list').parent().is(':visible')) {
        $('#toggle-work-modes').click();
    }
    $('#professions-list').parent().toggle("fast", function () {
        $(this).toggleClass('inline');
    })
});

// Toggling the contrat types selection pop-up
$('#toggle-types').on('click', function () {
    if ($('#professions-list').parent().is(':visible')) {
        $('#toggle-professions').click();
    }
    if ($('#work-modes-list').parent().is(':visible')) {
        $('#toggle-work-modes').click();
    }
    $('#types-list').parent().toggle("fast", function () {
        $(this).toggleClass('inline');
    })
});

// Toggling the work mode selection pop-up
$('#toggle-work-modes').on('click', function () {
    if ($('#professions-list').parent().is(':visible')) {
        $('#toggle-professions').click();
    }
    if ($('#types-list').parent().is(':visible')) {
        $('#toggle-types').click();
    }
    $('#work-modes-list').parent().toggle("fast", function () {
        $(this).toggleClass('inline');
    })
});

// Closing a selection pop-up when clicking the "X" icon inside it
// by simulating a click on the corresponding filter main button above
$('.close-button').on('click', function () {
    $(this).closest('.selector-container').find('.twobar').click();
});

// Building the URL search string based on the form content
// (search field content and filter checked items)
function buildSearchString() {
    var searchString = '?recherche=' + $('.splash-search').val();
    var professionsString = '';
    var contractTypesString = '';
    var workModesString = '';

    $('#professions-selector .scrolling-list input:checked').map(function () {
        professionsString += '&profession[]=' + $(this).val();
    });

    $('#types-selector .scrolling-list input:checked').map(function () {
        contractTypesString += '&type[]=' + $(this).val();
    });

    $('#work-modes-selector .scrolling-list input:checked').map(function () {
        workModesString += '&work_mode[]=' + $(this).val();
    });

    return searchString + professionsString + contractTypesString + workModesString;
}

// Managing search filter checkboxes and "top" list on the "opportunities" page.
function refreshJobsList(element) {
    $('#please-wait').css({ opacity: 0, display: 'flex' }).animate({
        opacity: 1
    }, 100);

    var currentUrlWithoutParams = window.location.href.match(new RegExp("^[^?]+"));
    var queryString = buildSearchString();
    var newUrl = currentUrlWithoutParams + queryString;
    var url = element.parent().data('url') + queryString;

    $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        success: function (response) {
            $('#jobs-result-container').html(response['data']);
            $('#please-wait').fadeOut(100);
            window.history.pushState({ page: "result" }, "Jobs search result", newUrl);
        },
        error: (function (jqXHR) {
            $('#please-wait').fadeOut(100);
            if (jqXHR.status == 500 || jqXHR.status == 0) {
                alert('Erreur de communication avec le serveur.\nLa page va être rechargée.');
                location.reload();
            } else {
                var err = JSON.parse(jqXHR.responseText);
                alert(err.Message);
            }
        })
    })
}

// Clicking an item in the "Top" list inserts it in the cleared search field above
// and refreshes the result list via Ajax if wehe are on the Jobs page.
// If we are on the homepage, we are redirected to the Jobs page.
$('.job-highlights li').on('click', function () {
    var regex = /\/jobs/g;
    if (window.location.pathname.match(regex)) {
        $('.splash-search').val($(this).text().trim());
        refreshJobsList($(this));
    } else {
        window.location.href = $(this).find('a').attr('href');
    }
})

// Clicking an item in the filter selection pop-ups refreshes the result list via Ajax
$('.selector-container .scrolling-list input:checkbox').on('change', function () {
    refreshJobsList($(this));
})

// Small screen menu handling
$('ul.group-list li.active').parent().parent().find('input[type=checkbox]').prop('checked', true);

// Newsletter subscription management
$('input#newsletter').on('change', function () {
    $('#please-wait').css({ opacity: 0, display: 'flex' }).animate({
        opacity: 1
    }, 100);
    var url = $(this).data('url');
    var inputElem = $(this);
    $.ajax({
        type: "POST",
        url: url,
        dataType: 'json',
        success: function (response) {
            $('#please-wait').hide();
            setTimeout(function () {
                if (response['subscription'] === true) {
                    alert('Vous êtes maintenant abonné(e) à la newsletter.\
                    \n \nA très bientôt !')
                } else {
                    alert('Vous êtes maintenant désabonné(e) de la newsletter.')
                }
            }, 50);
        },
        error: (function (jqXHR) {
            // Because the action failed, we need to restore the initial checkbox state.
            inputElem.prop("checked", !inputElem.prop("checked"));
            $('#please-wait').hide();
            setTimeout(function () {
                if (jqXHR.status == 500 || jqXHR.status == 0) {
                    //alert(JSON.stringify(jqXHR.responseJSON['errors']));
                    var err = JSON.stringify(jqXHR.responseJSON['errors']);
                    alert('Erreur de mise à jour de votre abonnement à la newsletter.\n'
                    + ' \n'
                    + 'Merci de réessayer ou de nous contacter si l\'erreur persiste\n'
                    + 'en nous communiquant le message d\'erreur suivant :\n'
                    + ' \n'
                    + err);
                } else {
                    var err = JSON.parse(jqXHR.responseText);
                    alert(err.Message);
                }
            }, 50);
        })
    })
});

(function () {
    // Managing historical timeline display
    $(window).on('load resize', function () {
        if ($(this).width() < 767) {
            return $('.history > li').css('margin-top', '');
        } else {
            return $('.history > li').css('margin-top', $('.history > li').height());
        }
    });

    // Giving random angles to "inside JobOpp'" images (between -12° and +12°)
    var images = document.querySelectorAll('.about-inside-jobopp img');
    for (var image of images) {
        var direction = Math.random() > 0.5 ? 1 : -1;
        var angle = direction * Math.random() * 12;
        image.style.setProperty('--rotation-angle', angle + 'deg');
    }

    // Managing the increasing counter for the number of available jobs
    // See https://stackoverflow.com/questions/16994662/count-animation-from-number-a-to-b
    function animateValue(id, start, end, duration) {
        // assumes integer values for start and end

        var obj = document.getElementById(id);
        var range = end - start;
        // no timer shorter than 50ms (not really visible any way)
        var minTimer = 50;
        // calc step time to show all interediate values
        var stepTime = Math.abs(Math.floor(duration / range));

        // never go below minTimer
        stepTime = Math.max(stepTime, minTimer);

        // get current time and calculate desired end time
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;

        function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - (remaining * range));
            obj.innerHTML = value;
            if (value == end) {
                clearInterval(timer);
            }
        }

        timer = setInterval(run, stepTime);
        run();
    }
    var targetId = "jobs-count-animation";
    if ($("#jobs-count-animation").length) {
        animateValue(targetId, 0, $("div#nb-jobs").html(), 4000);
    }

}).call(this);

/* TODO: (WIP) correction du bug des lien mailto si aucun mailer configuré sur le device.
// See https://www.uncinc.nl/nl/articles/dealing-mailto-links-if-no-mail-client-available
(function($) {
    $('a[href^=mailto]').each(function() {
      var href = $(this).attr('href');
      $(this).click(function(e) {
          e.preventDefault();
        var t;
        var self = $(this);
  
        $(window).blur(function() {
          // The browser apparently responded, so stop the timeout.
          clearTimeout(t);
        });
  
        t = setTimeout(function() {
          // The browser did not respond after 500ms, so open an alternative URL.
          //alert("pas de mailto");
          let modalCloseIcon = document.getElementsByClassName('modal-close')[0];
          if (document.contains(modalCloseIcon)) {
            modalCloseIcon.click();
          }
          window.opener.location.href = 'https://www.jobopportunit.com/contact';
          //document.location.href = 'https://www.jobopportunit.com/contact';
        }, 500);
      });
    });
})(jQuery);
*/